<template>
  <div>
    <!-- 种类 -->
    <div class="work-nav">
      <span class="type">Type</span>
      <div class="nav-list">
        <span
          :class="['nav-item', item.active ? 'active' : '']"
          v-for="(item, i) in cateList"
          :key="i"
          @click="backWorks(i)"
          >{{ item.ename }}</span
        >
      </div>
    </div>
    <!-- 案例详情 -->
    <div class="case">
      <div class="case-title">
        <div class="content-title">项目</div>
        <div class="title-text">{{ caseInfo.title }}</div>
        <div class="line"></div>
        <div class="counts">
          <div class="date">
            <img src="@/assets/news/data.png" alt="" class="data-icon" />
            <span class="time">{{ caseInfo.createtime }}</span>
          </div>
          <div class="date" style="margin-right: 0px">
            <img src="@/assets/news/watch.png" alt="" class="watch-icon" />
            <span class="time">{{ caseInfo.views }}</span>
          </div>
        </div>
      </div>
      <div class="case-content">
        <div
          class="content-text"
          id="content-text"
          v-html="caseInfo.content"
        ></div>
        <!-- <div class="content-text">{{ caseInfo.content }}</div> -->
        <!-- <img src="@/assets/news/case1.png" alt="" />
        <img src="@/assets/news/case2.png" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkCate, getWorksInfo } from "@/api";
export default {
  data() {
    return {
      baseURL: "http://adminwxdesy.wxjoi.com/",
      cateList: [],
      caseInfo: {},
    };
  },
  mounted() {
    this.getCate();
    this.getCaseInfo();
  },
  updated() {
    var obj = document.getElementById("content-text");
    var imgs = obj.getElementsByTagName("img");
    for (var i = 0; i < imgs.length; i++) {
      imgs[i].style.width = "100%";
    }
  },
  methods: {
    // 获取案例分类
    async getCate() {
      try {
        this.cateList = [{ id: 0, name: "全部", ename: "全部" }];
        const result = await getWorkCate();
        this.cateList = [...this.cateList, ...result.list];
        this.cateList = this.cateList.map((item) => {
          this.$set(item, "active", false);
          return item;
        });
        this.cateList[this.$route.query.active].active = true;
        console.log(this.cateList);
      } catch (error) {
        alert(error);
      }
    },
    // 获取案例详情
    async getCaseInfo() {
      try {
        const result = await getWorksInfo({ id: this.$route.query.id });
        this.caseInfo = result.info;
      } catch (error) {
        alert(error);
      }
    },
    // 点击跳转上一页的分类
    backWorks(id) {
      window.localStorage.setItem("selectedId", id);
      this.$router.replace({ path: "/works" });
    },
  },
};
</script>
<style lang="less" scoped>
.work-nav {
  background: #ffffff;
  width: 100%;
  box-sizing: border-box;
  padding: 54px 44px 18px 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .type {
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    color: #080404;
  }
  .nav-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .nav-item {
      font-size: 7px;
      font-family: PingFang SC;
      color: #080404;
      margin-right: 15px;
      cursor: pointer;
      &.active {
        font-weight: bold;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
.case {
  padding: 20px 43px 42px 43px;
  background: #f8f8f8;
  .case-title {
    position: relative;
    .content-title {
      font-size: 7px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #1d1d1d;
      padding-bottom: 7px;
    }
    .title-text {
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #1d1d1d;
      padding-bottom: 10px;
    }
    .line {
      width: 20px;
      height: 2px;
      background: #000000;
    }
    .counts {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0px;
      right: 0px;
      .date {
        margin-right: 7px;
        .data-icon {
          width: 5px;
          height: 5px;
          margin-right: 4px;
          display: inline-block;
        }
        .watch-icon {
          width: 8px;
          height: 5px;
          margin-right: 4px;
          display: inline-block;
        }
        .time {
          font-size: 7px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #1d1d1d;
        }
      }
    }
  }
  .case-content {
    padding: 35px 87px 0px 86px;
    .content-text {
      font-size: 7px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }
}
</style>
